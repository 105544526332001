.myModalStock .modal-wrapper {
    background: white;
    border: 1px solid #d0cccc;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2), 0 7px 20px 0 rgba(0,0,0,0.17);
    margin: 100px auto 0;
    transition: all .8s;
    width: 70%;
}

.myModalStock .modal-header {
    background: #263238;
    height: 40px;
    line-height: 40px;
    padding: 5px 20px;
    text-align: right;
}

.myModalStock .modal-header h3 {
    color: white;
    float: left;
    margin: 0;
    padding: 0;
    line-height: 1rem;
}

.myModalStock .modal-body {
  padding: 10px 15px;
  text-align: center;
}

.myModalStock .modal-footer {
    background: #263238;
    height: 35px;
    padding: 15px;
}

.myModalStock .close-modal-btn {
    color: white;
    cursor: pointer;
    float: right;
    font-size: 30px;
	line-height: 1rem;
}

.myModalStock .close-modal-btn:hover {
    color: black;
}

.myModalStock .btn-cancel, 
  .myModalStock .btn-continue {
    background: coral;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    padding: 10px;
}

.myModalStock .btn-cancel {
    background-color: #b71c1c;
    float: left;
}

.myModalStock .btn-continue {
    background-color: #1b5e20;
    float: right;
}

.myModalStock .back-drop {
    background-color: rgba(48, 49, 48, 0.42);
    height: 100%;
    position: fixed;
    transition: all 1.3s;
    width: 100%;
}


.myModalStock {
	position: absolute;
	z-index: 99999;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
}
