.dashboard  .card-header {
  padding: 10px;
  color: #000000;
}

.dashboard  .card-body {
  padding: 10px;
  text-align: right;
}
.dashboard .valores {
  font-weight: 700;
  font-size: 22px;
}