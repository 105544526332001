* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-align: border-box;
}

body {
    background: #fafafa;
    font: 14px Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased !important;
	/* margin-top: 100px; */
}
/*
.main-content-container {
  margin: 0;
  padding: 0;
}

.main-content-container .main{
  margin: 20px;
}
*/

.maintop {
  box-shadow: 0 0.125rem 0.625rem rgb(90 97 105 / 12%);
  padding-top: 10px !important;
}
.maintop .top-icon {
  color: #0099bf;
  /* color: #1784b8; */
  font-size: 2.6em;
  margin-left: 5px;
  margin-right: 15px;
}
.maintop .page-title {
  font-size: 2.0em;
  margin-top: 5px;
  color: #0099bf;
}

.page-title {
  display: flex;
}

.page-title .top-icon {
  color: #0099bf;
  /* color: #1784b8; */
  font-size: 1.6em;
  margin-left: 5px;
  margin-right: 15px;
}

.page-title .title {
  font-size: 1.0em;
  margin-top: 10px;
  color: #0099bf;
}

.version {
  font-size: 11px;
  font-style: italic;
  text-align: center;
}