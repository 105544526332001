section#stock-list .negative {
    color:#ca042e;
}
section#stock-list .positive {
    color:#167d4b;
}

section#stock-list .total {
	font-weight: bold;
}
