.table {
  overflow: auto;
  overflow-x: scroll;
  /* display: block; */
  border-collapse: collapse;
  /* width: 100%; */
}
.table th {
  text-overflow: unset !important;
  /* white-space: normal !important; */
  overflow: auto;
}

.td-full-text {
  text-overflow: unset !important;
  /* white-space: normal !important; */
  word-break: break-word;
}


@media (max-width: 800px) {
  .table {
    table-layout: auto !important;
  }
  .table th {
    table-layout: auto !important;
  }
}