
.users-interno .topo {
  background-color: #FFF;
  text-align: center;
  min-height: 30px;
  padding: 5px;
}

.users-interno .barra-titulo {
  color:#000;
  font-size: 16px;
  margin: 20px 0;
}

.users-interno .robot-info .card-header {
  padding: 10px;
  color: #000000;
}

.users-interno .robot-info .card-body {
  padding: 10px;
  text-align: right;
}

.users-interno .cifrao {
  color: #b3b3b3;
  font-size: 14px;
}

.users-interno .robot-info .retorno_liquido, 
.users-interno .robot-info .patrimonio,
.users-interno .robot-info .drawdown {
  font-weight: 700;
  font-size: 28px;
}

.users-interno .robot-info .retorno_liquido, 
.users-interno .robot-info .patrimonio {
  color: #699c69;
}

.users-interno .grafico {
  min-height: 100%;
}

.users-interno .margin-topo {
  margin-top: 10px;
}

.users-interno .card {
  margin-bottom: 10px;
}