.modalRobot .modal-header {
  height: 40px;
  line-height: 40px;
  padding: 5px 20px;
  text-align: right;
}

.modalRobot .modal-header .modal-title {
  float: left;
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.modalRobot .modal-body {
  padding: 10px 15px;
}

.modalRobot .btn-save {
  background-color: #1b5e20;
  float: right;
}

.modalRobot .robotRow {
  margin-bottom: 10px;
}