.robot {
  border:1px solid red;
}

.robot .cloneButton {
  float: right;
  color: #349ebe;
}

.robot .card-header {
  background-color: #fafafa;
  text-align: center;
  padding: 10px;
}
.robot .card-header .infos{
  display: flex;
  justify-content: space-between;
}

.robot .card-header .infos .type{
  text-transform: uppercase;
}
.robot .posicionado {
  min-height: 30px;
  padding: 5px;
  margin-bottom: 20px;
  text-align: center;
  border:1px solid #b3b3b3;
  color: #b3b3b3;
}

.robot .posicionado .posicao {
  color: #000000;
}

.robot .cifrao {
  color: #b3b3b3;
  font-size: 14px;
}
.robot .valores {
  display: flex;
  
}

.retorno_liquido_titulo, .performance_titulo {
  color: #b3b3b3;
  font-size:16px;
}

.retorno_liquido, .performance {
  color: #699c69;
  font-weight: 700;
  font-size: 20px;
}
.performance .valor_negativo {
  color: #fd8282;
}

.performance .valor_positivo {
}


.status {
  color: #b3b3b3;
  margin-top: 20px;
  text-align: right;
}

.robot .btStop {
  color: #FFF;
  background-color: #FF0000 !important;
  border-color: #FF0000 !important;
  font-size: 22px;  
}

.robot .btStart {
  color: #FFF;
  background-color: #00FF00 !important;
  border-color: #00FF00 !important;
  border: none;
  font-size: 22px;  
}

.robot .cloneButton:hover, 
  .robot .btStart:hover,
  .robot .btStop:hover {
    cursor: pointer;
}